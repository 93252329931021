.container {
    width          : 100%;
    padding        : 60px 40px;
    position       : relative;
    display        : flex;
    justify-content: space-between;
    align-items    : center;
    border         : 2px solid #d3d3d3;
    margin-top     : 80px;
    margin-bottom  : 100px;

    @media (max-width: 992px) {
        padding       : 15px 15px;
        flex-direction: column;
        margin-top    : 50px;
        margin-bottom : 20px;
    }

    .headline {
        padding         : 0 30px;
        background-color: white;
        position        : absolute;
        top             : 0;
        left            : 50%;
        transform       : translate(-50%, -50%);
        width           : max-content;
        font-family     : "Afreshed";
        font-size       : 21px;
    }

    .button {
        width              : 100%;
        margin             : 0 20px;
        height             : 60px;
        background-position: center;
        background-repeat  : no-repeat;
        border-radius      : 10px;
        cursor             : pointer;
        transition         : transform .2s ease;

        @media (max-width: 992px) {
            width : 100%;
            margin: 10px 0;
        }

        &:hover {
            transform: scale(0.95);
        }

        &:active {
            transform: scale(0.9);
        }

        &.apple {
            background-size : 55px;
            background-color: black;
            background-image: url(../../assets/icons/appleLogo.png);
        }

        &.paypal {
            background-size : 100px;
            background-color: #ffc439;
            background-image: url(../../assets/icons/paypalLogo.png);
        }
    }
}