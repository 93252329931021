.container {
    width          : 100%;
    z-index        : 999;
    display        : flex;
    justify-content: space-between;
    align-items    : center;
    position       : relative;
    margin-bottom  : 50px;

    @media (max-width: 779px) {
        margin-bottom: 25px;
    }


    .item {
        text-align    : center;
        display       : flex;
        flex-direction: column;
        align-items   : center;

        .circle {
            width           : 70px;
            height          : 70px;
            border-radius   : 100%;
            display         : flex;
            align-items     : center;
            justify-content : center;
            background-color: #f2f2f2;
            font-family     : "Afreshed";
            font-size       : 21px;
            border          : 10px solid white;
            z-index         : 9;
        }

        &.active {
            .circle {
                background-color: rgb(133, 184, 188);
                color           : white;
            }

            &::before {
                background-color: rgb(133, 184, 188);
            }
        }

        &::before {
            height          : 2px;
            width           : calc(50% - 10px);
            background-color: #f2f2f2;
            content         : '';
            position        : absolute;
            top             : 34px;
            display         : block;
            z-index         : 0;
        }

        &:first-child {
            &::before {
                left: 10px;
            }
        }

        &:nth-child(2) {
            &::before {
                right: 10px;
            }
        }

        &:last-child {
            &::before {
                width: 0;
            }
        }


        span {
            font-size: 14px;

            &.active {
                font-weight: 900;
            }
        }

    }
}