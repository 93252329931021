.container {
    width           : 100%;
    position        : sticky;
    top             : 0;
    bottom          : 0;
    min-height      : 100vh;
    background-color: #f6f6f6;
    padding         : 30px;

    .inner {
        width    : 100%;
        max-width: 682px;

        .priceContainer {
            font-size: 21px;
        }

        .iconRow {
            width          : 100%;
            display        : flex;
            justify-content: space-between;
            align-items    : center;
            padding        : 20px;

            @media (max-width: 992px) {
                display: none;
            }

            img {
                height: auto;
                width : 40px;
            }
        }

        .cartItemContainer {
            width  : 100%;
            display: flex;

            p {
                font-size: 14px;
            }

            .cartItemImage {
                width: 35%;

                img {
                    width: 100%;
                }
            }

            .cartItemContent {
                width       : 65%;
                padding-left: 20px;
                text-align  : right;


            }
        }

        hr {
            height       : 1px;
            margin-top   : 30px;
            margin-bottom: 30px;
        }

        .cartTable {
            width: 100%;

            tr {
                td {
                    &:last-child {
                        text-align: right;
                    }

                    vertical-align: top;
                }
            }
        }

        .iconTable {
            tr {
                td {
                    &:first-child {
                        padding-right: 10px;
                    }

                    padding-bottom: 20px;
                    vertical-align: top;
                }
            }
        }

        small {
            font-size: 12px;
            display  : block;
        }
    }

    &.isMobile {
        position  : relative !important;
        min-height: auto;
        padding   : 15px;

        @media (min-width: 992px) {
            display: none;
        }

        .inner {
            font-size: 14px;

            .priceContainer {
                font-size: 16px;
            }

            h3 {
                font-size: 18px;
            }

            .cartItemContainer {
                width  : 100%;
                display: flex;

                p {
                    display: none;
                }

                .cartItemImage {
                    width: 30%;

                    img {
                        width: 100%;
                    }
                }

                .cartItemContent {
                    width       : 70%;
                    padding-left: 0px;
                    text-align  : right;
                }
            }

        }
    }
}