.overlay {
    width           : 100%;
    height          : 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    position        : fixed;
    display         : flex;
    align-items     : center;
    justify-content : center;
    z-index         : 99999;
    top             : 0;
    left            : 0;
}

h3 {
    &.success {
        color: var(--green-color);
    }

    &.info {
        color: var(--yellow-color);
    }

    &.error {
        color: var(--red-color);
    }
}


.container {
    width           : calc(100% - 50px);
    max-width       : 500px;
    background-color: white;
    padding-top     : 20px;
    padding-bottom  : 20px;
    padding-left    : 20px;
    padding-right   : 20px;
    border-radius   : 15px;

    &.small {
        position  : fixed;
        right     : 30px;
        bottom    : 30px;
        box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.08);
    }
}


.button {
    width     : 100%;
    text-align: center;
    cursor    : pointer;

    &.success {
        background-color: var(--green-color);
    }

    &.info {
        color           : var(--text-color);
        background-color: var(--yellow-color);
    }

    &.error {
        background-color: var(--red-color);
    }

}

.small_button {
    background-color: transparent !important;
    cursor          : pointer;

    &.success {
        color: var(--green-color);
    }

    &.info {
        color: var(--yellow-color);
    }

    &.error {
        color: var(--red-color);
    }
}

.text {
    margin-bottom: 20px;
}