.container {
    width           : 100%;
    padding         : 10px 15px;
    padding-left    : 20px;
    background-color: #f6f6f6;
    left            : 0;
    top             : 0;
    position        : relative;
    z-index         : 999;
    display         : flex;
    justify-content : center;

    .inner {
        max-width      : 1920px;
        width          : 100%;
        display        : flex;
        align-items    : center;
        justify-content: space-between;

        @media (max-width: 992px) {
            justify-content: center;
        }

        .logo {
            height: 50px;

            @media (max-width: 992px) {
                height    : 30px;
                width     : 80%;
                object-fit: contain;
            }
        }

        .certificate {
            height: 80px;

            @media (max-width: 992px) {
                display: none;
            }
        }

        .navigation {
            display    : flex;
            align-items: center;
            font-size  : 21px;
            font-family: "Afreshed";


            @media (max-width: 992px) {
                display: none;
            }

            a {
                padding        : 0 15px;
                color          : #5c5959;
                text-decoration: none;

                &:hover {
                    color: #0a58ca;
                }
            }

            img {
                width : 20px;
                height: auto !important;
            }
        }
    }
}